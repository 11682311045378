import { colours } from "../constants/colours";

type Props = {
  letter: string;
  isCorrect: boolean;
  showVowels: boolean;
  reveal: boolean;
  revealOrderIndexByLetter: number;
};

const getColours = (isVowel: boolean, revealed: boolean): string[] => {
  if (revealed) {
    return [
      colours.correct.background.light,
      colours.correct.background.dark,
      isVowel ? colours.vowel.border.light : colours.correct.border.light,
      isVowel ? colours.vowel.border.dark : colours.correct.border.dark,
    ];
  }

  if (isVowel) {
    return [
      colours.vowel.background.light,
      colours.vowel.background.dark,
      colours.vowel.border.light,
      colours.vowel.border.dark,
    ];
  }

  return [
    colours.consonant.background.light,
    colours.consonant.background.dark,
    colours.consonant.border.light,
    colours.consonant.border.dark,
  ];
};

const delays = [
  "delay-0",
  "delay-150",
  "delay-300",
  "delay-450",
  "delay-600",
  "delay-750",
  "delay-900",
  "delay-1050",
  "delay-1200",
  "delay-1350",
  "delay-1500",
  "delay-1650",
  "delay-1800",
  "delay-1950",
  "delay-2100",
];

const getDelay = (index: number) => {
  if (index > delays.length - 1) {
    index = delays.length - 1;
  }
  return delays[index];
};

const Letter = ({
  letter,
  isCorrect,
  showVowels,
  reveal,
  revealOrderIndexByLetter,
}: Props) => {
  const isVowel = ["a", "e", "i", "o", "u"].includes(letter) && showVowels;

  const colours = getColours(isVowel, isCorrect);

  const [backgroundStyle, darkBackgroundStyle, borderStyle, darkBorderStyle] =
    colours;

  return (
    <span
      className={`relative border-2 basis-11 h-11 md:basis-[3.25rem] md:h-[3.25rem] px-2 inline-block align-middle text-center text-xl md:text-3xl leading-9 md:leading-[2.875rem] font-bold m-0.5 rounded-lg ${borderStyle} ${darkBorderStyle}`}
    >
      <span
        className={`z-10 absolute left-0 right-0 opacity-0 transition-all duration-500 ${getDelay(
          revealOrderIndexByLetter
        )} ${isCorrect || reveal ? "opacity-100" : ""}`}
      >
        {isCorrect || reveal ? letter.toUpperCase() : <>&nbsp;</>}
      </span>
      <div
        className={`absolute z-0 ${backgroundStyle} ${darkBackgroundStyle} bottom-0 left-0 right-0 ${
          isCorrect ? "h-full" : "h-1"
        } transition-all ${getDelay(revealOrderIndexByLetter)}`}
      ></div>
    </span>
  );
};

export default Letter;
