import InfoIcon from "./icons/InfoIcon";
import { useLocation } from "react-router";
import { useAppDispatch } from "../hooks";
import { track } from "../app/trackingSlice";
import { ModalType, showModal } from "../app/modalSlice";
import LeftChevronIcon from "./icons/LeftChevronIcon";

type Props = {
  className?: string;
};

const Header = ({ className }: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const isHome = location.pathname === "/";

  const onInfoClick = () => {
    dispatch(track({ eventName: "info_clicked" }));
    dispatch(showModal(ModalType.Info));
  };

  return (
    <header
      className={`${className} grid grid-cols-[2em_1fr_2em] justify-items-center`}
    >
      {!isHome && (
        <button className="ml-2 mr-1" onClick={() => history.back()}>
          <LeftChevronIcon className="fill-white" />
        </button>
      )}
      {isHome && (
        <div className="">
          <div style={{ width: "15px" }}>&nbsp;</div>
        </div>
      )}

      <h1 className="text-2xl text-center">Hang Five</h1>

      <button className="mr-3" onClick={onInfoClick}>
        <InfoIcon className="fill-white" />
      </button>
    </header>
  );
};

export default Header;
