import GameScreen from "./GameScreen";
import Toast from "./Toast";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  makeGuess,
  selectGameStatus,
  selectGuessedLetters,
  selectHasLoadingError,
  selectIsLearning,
  selectIsLoading,
  selectJustFinished,
  selectPuzzle,
  startPlaying,
} from "../app/gameSlice";
import { selectToastMessages } from "../app/toastSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import GameStatus from "../types/GameState";
import ErrorScreen from "./ErrorScreen";
import { ModalType, showModal } from "../app/modalSlice";
import { logInfo } from "../helpers/tracing";

const Gameplay = () => {
  const showError = useAppSelector(selectHasLoadingError);
  const isLoading = useAppSelector(selectIsLoading);

  const toastMessages = useAppSelector(selectToastMessages);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onChange = (input: string) => {
    console.log("guessed", input);
    dispatch(makeGuess({ letter: input }));
  };

  // calculate max number of letters not guessed in each word
  const puzzle = useAppSelector(selectPuzzle);
  const allGuessedLetters = useAppSelector(selectGuessedLetters);

  useEffect(() => {
    dispatch(startPlaying());
  }, [dispatch, puzzle]);

  const lettersNotGuessed = puzzle.words.map((word) =>
    word
      .split("")
      .filter((letter) => !allGuessedLetters.includes(letter.toLowerCase()))
  );

  const maxLettersNotGuessed = Math.max(
    ...lettersNotGuessed.map((letters) => letters.length)
  );

  console.log({ lettersNotGuessed, maxLettersNotGuessed });

  logInfo({ message: "Rendering GamePlay" });

  const gameStatus = useAppSelector(selectGameStatus);
  const justFinished = useAppSelector(selectJustFinished);

  const isLearning = useAppSelector(selectIsLearning);

  useEffect(() => {
    if (justFinished && gameStatus !== GameStatus.IN_PROGRESS) {
      if (!isLearning) {
        const baseDelay = 3000;
        const letterDelay = 150 * maxLettersNotGuessed;

        setTimeout(() => {
          dispatch(showModal(ModalType.Stats));
        }, baseDelay + letterDelay);
      }
    }
  }, [
    gameStatus,
    navigate,
    maxLettersNotGuessed,
    justFinished,
    dispatch,
    isLearning,
  ]);

  return (
    <>
      <GameScreen
        onKeyPress={onChange}
        loadNextGame={() => window.location.reload()}
        isLoading={isLoading ?? false}
      />

      {showError && <ErrorScreen />}

      {toastMessages.map((message, index) => (
        <Toast key={index} message={message} />
      ))}
    </>
  );
};

export default Gameplay;
