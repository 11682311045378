import { saveGameToLocalStorage, saveStatsToLocalStorage } from "../helpers/persistence";
import { getDeviceId, getUid, replaceUid } from "../helpers/system";
import { GamePayload } from "../types/GamePayload";
import { MigrationPayload } from "../types/MigrationPayload";
import { selectGame, selectIsArchive, selectIsLearning, selectStats } from "./gameSlice";
import { startAppListening } from "./listenerMiddleware"
import { beginCompleteMigration, finishCompleteMigration, handBackToNative, selectMigrationId, startMigration } from "./migrationSlice"
import { setHasMigrated } from "./settingsSlice";



export const registerMigrationListener = () => {
  startAppListening({
    actionCreator: startMigration,
    effect: async (_, api) => {
      console.log("Starting migration");

      const state = api.getState();

      const isLearning = selectIsLearning(state);
      const isArchive = selectIsArchive(state);
      const { startTimestamp } = selectGame(state);

      // send them to migration place
      let game = null;
      let letterPresses = null;

      if (!isLearning && !isArchive && startTimestamp) {
        game = JSON.parse(
          localStorage.getItem("game") ?? "{}"
        ) as GamePayload;
        letterPresses = JSON.parse(
          localStorage.getItem("letterPress") ?? "[]"
        );
      }

      const deviceId = getDeviceId();
      const uid = getUid();
      const stats = selectStats(state);
      const migrationId = selectMigrationId(state);

      const migrationPayload: MigrationPayload = {
        deviceId,
        game,
        letterPresses,
        migrationId: migrationId!,
        stats,
        uid,
      };

      const encodedPayload = btoa(JSON.stringify(migrationPayload));

      const nextUrl = "com.playhangfive://migrate?payload=" + encodedPayload;
      api.dispatch(setHasMigrated());
      api.dispatch(handBackToNative(nextUrl));
    }
  });

  startAppListening({
    actionCreator: beginCompleteMigration,
    effect: async (action, api) => {

      const payload = action.payload;
      // these all have side-effects. move to observer later on
      // migrate the user
      replaceUid(payload.uid);
      if (payload.stats) {
        saveStatsToLocalStorage(payload.stats);
      }

      if (payload.game) {
        const gameState = {
          isArchive: false,
          isLearning: false,
          letterPresses: payload.letterPresses,
          puzzle: {
            date: "",
            gameHash: payload.game.gameHash,
            gameIndex: payload.game.gameIndex,
            puzzleId: payload.game.puzzleId,
            theme: "",
            words: [],
          },
        };

        saveGameToLocalStorage(gameState);
      }

      api.dispatch(finishCompleteMigration())
    }
  });
}
