import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect } from "react";
import {
  beginCompleteMigration,
  selectMigrationRedirectUrl,
  startMigration,
} from "../app/migrationSlice";
import { MigrationPayload } from "../types/MigrationPayload";

export const MigrateToNative = () => {
  const dispatch = useAppDispatch();

  const redirectUrl = useAppSelector(selectMigrationRedirectUrl);

  useEffect(() => {
    dispatch(startMigration());
  }, [dispatch]);

  useEffect(() => {
    if (redirectUrl) {
      window.location = redirectUrl as unknown as Location;
    }
  }, [redirectUrl]);

  const { platform } = useParams<{ platform: string }>();

  const location = useLocation();

  useEffect(() => {
    if (platform === "android") {
      // is there a payload in the fragment?
      let rawPayload = null;

      if (location.hash && location.hash.length) {
        const searchParams = new URLSearchParams(location.hash.slice(1));
        rawPayload = searchParams.get("payload");
      }

      let payload = null;
      if (rawPayload) {
        // decode the payload
        payload = JSON.parse(atob(rawPayload)) as MigrationPayload;

        // do something with the payload
        console.log("Decoded payload", payload);
      }

      if (payload) {
        dispatch(beginCompleteMigration(payload));
      } else {
        dispatch(startMigration());
      }
    }
  }, [dispatch, location.hash, platform]);

  return (
    <div className="bg-white dark:bg-slate-900 flex items-center justify-center min-h-screen">
      <h1 id="transferring" className="text-xl text-black dark:text-white">
        Transferring your stats...
      </h1>

      {/* <Button
        onClick={() => {
          window.location = nextUrl as unknown as Location;
        }}
      >
        Reload 
      </Button>*/}
    </div>
  );
};
