import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export type Message = {
  tag: string;
  variant?: string;
  body: string;
};

type MessagesState = {
  messages: Message[];
};

const msg = (tag: string, body: string, variant?: string): Message => ({
  body,
  tag,
  variant,
});

const initialState: MessagesState = {
  messages: [
    msg("lost", "Game over! You lost 😭"),
    msg("won", "Game over! You won 😊"),
    msg("share_cta", "Share my results"),
    msg("share_stats_cta", "Share today's result"),
    msg("win_streak_stat", "win streak"),
    msg("play_streak_stat", "play streak"),
    msg("won_stat", "won"),
    msg("longest_win_streak_stat", "longest win streak"),
    msg("longest_play_streak_stat", "longest play streak"),
    msg("played_stat", "played"),
    msg("todays_theme", "Today’s theme:"),
    msg(
      "stats_intro.puzzle_in_progress",
      "You can still solve puzzle #{gameIndex}!"
    ),
    msg(
      "stats_intro.solved_first_game",
      "Not bad for your first go. Come back tomorrow and start your streak!"
    ),
    msg(
      "stats_intro.solved",
      "Nice one. Come back tomorrow and increase your streak!"
    ),
    msg(
      "stats_intro.lost_first_game",
      "Now you've got the hang of it, come back tomorrow and start your streak!"
    ),
    msg(
      "stats_intro.lost",
      "Come back tomorrow and try again and start your next streak!"
    ),
    msg("rank_text.top", "You're the top player today! 🔝"),
    msg("rank_text.percentile", "You're in the top {percentile}%! 🥳"),
    msg("stats.solved_rate", "📈 {solvedRate}% of players solved it today."),
    msg("stats.loading", "📈 Loading..."),
    msg("stats.you_finished_in", "You finished in"),
    msg("stats_title.in_progress", "Keep going!"),
    msg("stats_title.solved", "You solved it!"),
    msg("stats_title.lost", "You lost!"),
    msg("learning_theme", "The theme"),
    msg("guide_intro_continue_button", "Let's go"),
    msg("guide_continue_button", "Got it"),
    msg("stats_screen_title", "Your statistics"),
    msg("stats_screen_as_of_prefix", "as of puzzle #"),
    msg("stats_screen_play_todays_puzzle", "Play today's puzzle"),
    msg("start_welcome", "Welcome to Hang Five"),
    msg("start_body_1", "The aim of the game is to guess five related words."),
    msg("start_body_2", "Play every day to build your streak."),
    msg("start_puzzle_prefix", "Puzzle #"),
    msg("how_to_play_title", "How to play Hang Five"),
    msg("play_learning_game", "Play a practise game"),
    msg("start_screen_play_learning_game", "Play a practise game first"),
    msg("start_screen_how_to_play", "How to play"),
    msg("start_screen_stats", "My statistics"),
    msg("made_by_zemobo", "Made by Zemobo"),
    msg("privacy_policy", "Privacy policy"),
  ],
};

type MessageIdentifier = { tag: string; variant?: string };

const mergeMessages = (existing: Message[], newMessages: Message[]) => {
  const newTagsAndVariants: MessageIdentifier[] = newMessages.map(
    (message) => ({
      tag: message.tag,
      variant: message.variant,
    })
  );

  const merged = [...existing].filter((message) => {
    const isUnique = !newTagsAndVariants.some(
      (newMessage) =>
        newMessage.tag === message.tag && newMessage.variant === message.variant
    );

    return isUnique;
  });

  return [...merged, ...newMessages];
};

const messagesSlice = createSlice({
  initialState,
  name: "mesages",
  reducers: {
    setMessages: (state, action: PayloadAction<Message[]>) => {
      state.messages = mergeMessages(state.messages, action.payload);
    },
  },
});

export const { setMessages } = messagesSlice.actions;

export const selectMessage = createSelector(
  [
    (state: RootState) => state.messages,
    (_state: RootState, tag: string) => tag,
  ],
  (messages, tag) => {
    const relevantMessages = messages.messages.filter(
      (message) => message.tag === tag
    );

    let message: Message | undefined;

    if (relevantMessages.length > 1) {
      // pick one at random
      const randomIndex = Math.floor(Math.random() * relevantMessages.length);
      message = { ...relevantMessages[randomIndex] };

      if (!message.variant) {
        message.variant = "default";
      }
    } else if (relevantMessages.length === 1) {
      message = { ...relevantMessages[0] };
      message.variant = "default";
    } else {
      message = { body: tag, tag, variant: "default" };
    }

    return message;
  }
);

export default messagesSlice.reducer;
