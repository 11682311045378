import { v4 as uuid } from "uuid";

const getBuildHash = () =>
  import.meta.env.VITE_GIT_HASH.substring(0, 7) ?? "dev";

const getVersionNumber = () => APP_VERSION;

type Uid = string;

const createUid = () => {
  const userIdentifier = uuid();
  localStorage.setItem("uid", userIdentifier);
  return userIdentifier;
};

const getUid: () => Uid = () => {
  return localStorage.getItem("uid") ?? createUid();
};

export const replaceUid = (uid: string) => {
  localStorage.setItem("uid", uid);
  return uid;
}


export const getDeviceId: () => string = () => {
  let deviceId = localStorage.getItem("deviceId");

  if (!deviceId) {
    deviceId = uuid();
    localStorage.setItem("deviceId", deviceId);
  }

  return deviceId;
}

let cachedNativeAppVersion: [string, string] | null | undefined = undefined;

export const getNativeAppVersion = () => {
  if (cachedNativeAppVersion) {
    return cachedNativeAppVersion;
  }
  // get the useragent
  const userAgent = navigator.userAgent;
  // extract the HangFive version, if present
  const hangFiveVersionMatch = userAgent.match(/HangFive\/(\d+\.\d+\.\d+(?:\.\d+)?)/);
  const hangFiveVersion = hangFiveVersionMatch
    ? hangFiveVersionMatch[1]
    : undefined;

  // extract the operating system (Android or iOS)
  const osMatch = userAgent.match(/Android|iPhone OS/);
  const os = osMatch ? osMatch[0] : "undefined";

  if (hangFiveVersion && os) {
    cachedNativeAppVersion = [os, hangFiveVersion];
  }
  else {
    cachedNativeAppVersion = null;
  }

  return cachedNativeAppVersion;
}

export const isNative = () => {
  return getNativeAppVersion() !== null;
}

export const getPlatform = () => {
  let platform: string | undefined = sessionStorage.getItem("platform") ?? undefined;

  const nativeAppVersion = getNativeAppVersion();

  if (nativeAppVersion) {
    return nativeAppVersion[0].toLowerCase();
  } if (document.referrer.includes("android-app://com.zemobo.hangfive.twa")) {
    platform = "android-twa";
  } else if (window.matchMedia("(display-mode: standalone)").matches) {
    platform = "pwa";
  }

  if (!platform) {
    platform = "web";
  }

  if (platform === "pwa" || platform === "web") {
    const userAgent = navigator.userAgent;
    const isAndroid = userAgent.includes("Android");
    const isiOS = userAgent.includes("iPhone OS");

    if (isAndroid) {
      platform = `${platform}-android`;
    } else if (isiOS) {
      platform = `${platform}-ios`;
    }
  }

  sessionStorage.setItem("platform", platform);

  return platform;
};


export { getBuildHash, getVersionNumber, getUid };
export type { Uid };
