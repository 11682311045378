import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from "uuid";
import { MigrationPayload } from '../types/MigrationPayload';

enum MigrationStatus {
  Unknown = "Unknown",
  Migrating = "Migrating",
  WaitingForNative = "WaitingForNative",
  Completing = "Completing",
}

interface MigrationState {
  status: MigrationStatus;
  migrationId?: string;
  redirectUrl?: string;
  skipNativePrompt?: boolean;
}

const initialState: MigrationState = {
  status: MigrationStatus.Unknown,
};

const migrationSlice = createSlice({
  initialState,
  name: "migration",
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beginCompleteMigration: (state, _action: PayloadAction<MigrationPayload>) => {
      state.status = MigrationStatus.Completing;
    },
    finishCompleteMigration: (state) => {
      state.redirectUrl = "/";
    },
    handBackToNative: (state, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
      state.status = MigrationStatus.WaitingForNative;
    },
    skipNativePrompt: (state) => {
      state.skipNativePrompt = true;
    },
    startMigration: (state) => {
      state.migrationId = uuid();
      state.status = MigrationStatus.Migrating;
    },
  },
});

const selectMigration = (state: { migration: MigrationState }) => state.migration;

export const selectMigrationRedirectUrl =
  createSelector(selectMigration, (migration) => migration.redirectUrl);

export const selectMigrationId =
  createSelector(selectMigration, (migration) => migration.migrationId);

export const selectSkipNativePrompt =
  createSelector(selectMigration, (migration) => migration.skipNativePrompt);

export const { beginCompleteMigration, finishCompleteMigration, handBackToNative, skipNativePrompt, startMigration } = migrationSlice.actions;
export default migrationSlice.reducer;