import { useEffect } from "react";
import {
  selectRemainingLetters,
  selectGameIndex,
  selectIsLearning,
  selectGameStatus,
  selectPuzzleLetters,
} from "../app/gameSlice";
import { isVowel } from "../helpers/letters";
import { useAppDispatch, useAppSelector } from "../hooks";
import Theme from "./Theme";
import GameState from "../types/GameState";
import { clearTitle, setTitle } from "../app/pageSlice";
import LivesIndicator from "./LivesIndicator";
import GameResult from "./GameResult";
import ShareButton from "./ShareButton";
import Countdown from "./Countdown";
import { getMidnightTonight } from "../helpers/dates";
import WordGrid from "./WordGrid";
import Keyboard from "./Keyboard";
import { Santa } from "./Santa";
import SpinnerIcon from "./icons/SpinnerIcon";
import { LearningGameSummary } from "./LearningGameSummary";
import { ModalType, showModal } from "../app/modalSlice";
import { logInfo } from "../helpers/tracing";

type Props = {
  onKeyPress: (input: string) => void;
  loadNextGame: () => void;
  isLoading: boolean;
};

const GameContent = ({ onKeyPress, loadNextGame, isLoading }: Props) => {
  logInfo({ message: "Rendering game content" });

  const status = useAppSelector(selectGameStatus);

  const dispatch = useAppDispatch();

  const puzzleIsLoaded = useAppSelector(selectPuzzleLetters)?.length > 0;

  const remainingLetters = useAppSelector(selectRemainingLetters);

  const onlyVowelsLeft = remainingLetters.every(isVowel);

  const isGameOver = status === GameState.SOLVED || status === GameState.LOST;

  const isLearning = useAppSelector(selectIsLearning);

  const gameIndex = useAppSelector(selectGameIndex);

  useEffect(() => {
    dispatch(
      setTitle({
        gameIndex: isLearning ? undefined : gameIndex,
        title: isGameOver ? "Game over" : "",
      })
    );

    return () => {
      dispatch(clearTitle());
    };
  }, [dispatch, isLearning, isGameOver, gameIndex]);

  const showSanta = false; // useAppSelector(selectShowSanta);

  return (
    <>
      {isLoading && (
        <div className="flex-auto flex items-center justify-center">
          <div className="text-center">
            <SpinnerIcon
              size={80}
              className="inline-block fill-slate-300 animate-spin"
            />
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          <div className="h-full grid grid-rows-[auto_1fr_auto]">
            <div>
              <Theme />
              <div className="w-full z-20">
                <div className="max-w-md mx-auto">
                  <div
                    className={`mt-2 mx-4 ${
                      !isGameOver ? "flex justify-between items-center" : ""
                    }`}
                  >
                    {!isGameOver && (
                      <div className="flex-1 h-5">
                        {puzzleIsLoaded &&
                          onlyVowelsLeft &&
                          status === GameState.IN_PROGRESS && (
                            <p className="text-sm font-bold">
                              Only vowels left!
                            </p>
                          )}
                      </div>
                    )}
                    <div className="text-center">
                      {puzzleIsLoaded && <LivesIndicator className="flex-1" />}
                    </div>
                  </div>
                  {isGameOver && (
                    <div className="text-center">
                      <GameResult />
                      {!isLearning && (
                        <>
                          <ShareButton />
                          <div className="my-2">
                            <Countdown
                              targetDate={getMidnightTonight()}
                              onTimerEnd={loadNextGame}
                            />
                          </div>
                          <p className="text-sm">
                            <a
                              className="underline"
                              href="#"
                              onClick={() =>
                                dispatch(showModal(ModalType.Stats))
                              }
                            >
                              See my rank
                            </a>
                          </p>
                        </>
                      )}
                      {isLearning && <LearningGameSummary />}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`${
                isGameOver ? "transition-all items-end" : "items-center"
              } flex-grow overflow-y-auto flex justify-center`}
            >
              <div className="w-full">
                <div className="max-w-md mx-auto">
                  {/* Words */}
                  <WordGrid
                    className={`${
                      isGameOver ? "scale-75 h-[19em]" : ""
                    } flex-grow mx-auto w-[21em] md:w-[24.5em]`}
                  />
                </div>
              </div>
            </div>
            <div className="w-full sticky bottom-0 z-10 bg-white dark:bg-slate-900">
              <div className="max-w-md mx-auto">
                <Keyboard
                  onKeyPress={onKeyPress}
                  disabled={
                    status === GameState.SOLVED || status === GameState.LOST
                  }
                  disableConsonants={onlyVowelsLeft}
                  gameOver={isGameOver}
                />
              </div>
            </div>
          </div>
          <div id="toast-container"></div>
          {showSanta && <Santa />}
        </>
      )}
    </>
  );
};

export default GameContent;
