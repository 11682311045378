import { selectCorrectGuesses, selectIncorrectGuesses } from "../app/gameSlice";
import { colours } from "../constants/colours";
import { useAppSelector } from "../hooks";

type Props = {
  disabled: boolean;
  onKeyPress: (key: string) => void;
  character: string;
  className?: string;
  isPressed: boolean;
  height: "small" | "normal";
};

const getKeyClass = (
  correctKeys: string[],
  incorrectKeys: string[],
  key: string,
  disabled: boolean,
  isPressed: boolean
) => {
  const isVowel = ["a", "e", "i", "o", "u"].includes(key.toLowerCase());

  const classNames = ["transition-all"];

  if (correctKeys.includes(key.toLowerCase())) {
    if (isVowel) {
      classNames.push(
        `${colours.correct.background.light} ${colours.correct.background.dark} ${colours.correct.border.light} ${colours.correct.border.dark}`
      );
    } else {
      classNames.push(
        `${colours.correct.background.light} ${colours.correct.background.dark} ${colours.correct.border.light} ${colours.correct.border.dark}`
      );
    }
  } else if (incorrectKeys.includes(key.toLowerCase())) {
    classNames.push(
      `${colours.incorrect.background.light} ${colours.incorrect.background.dark} ${colours.incorrect.border.light} ${colours.incorrect.border.dark}`
    );
  } else if (disabled) {
    classNames.push(
      `${colours.disabled.background.light} ${colours.disabled.background.dark} ${colours.disabled.border.light} ${colours.disabled.border.dark}`
    );
  } else if (isVowel) {
    classNames.push(
      `${colours.key.background.light} ${colours.key.background.dark} ${colours.vowel.border.light} ${colours.vowel.border.dark} ${colours.key.active.background.light} ${colours.key.active.background.dark}`
    );
  } else {
    classNames.push(
      `${colours.key.background.light} ${colours.key.background.dark} ${
        colours.key.border.light
      } ${colours.key.border.dark} ${colours.key.active.background.light} ${
        colours.key.active.background.dark
      } ${colours.key.active.border.light} ${
        colours.key.active.border.light
      }  ${
        isPressed
          ? ` bg-slate-300 dark:bg-slate-900 border-slate-300 dark:border-slate-900`
          : ""
      } ${
        isVowel
          ? `border-blue-300 dark:border-blue-300`
          : `border-slate-200 dark:border-slate-800 active:border-slate-300 dark:active:border-slate-900`
      }`
    );
  }

  return classNames.join(" ");
};

const getHeightClass = (height: "small" | "normal") => {
  if (height === "small") {
    return "h-8 sm:h-12";
  }

  return "h-11 sm:h-16";
};

const Key = ({
  disabled,
  onKeyPress,
  character,
  className,
  isPressed,
  height,
}: Props) => {
  const correctKeys = useAppSelector(selectCorrectGuesses);
  const incorrectKeys = useAppSelector(selectIncorrectGuesses);

  return (
    <button
      disabled={disabled}
      className={`${
        className ?? ""
      } border-2 rounded m-0.5 pd-3 inline-block flex-1  ${getHeightClass(
        height
      )} ${getKeyClass(
        correctKeys,
        incorrectKeys,
        character,
        disabled,
        isPressed
      )} transition-all`}
      key={character}
      onClick={() => onKeyPress(character.toLowerCase())}
    >
      <span className="font-bold">{character}</span>
    </button>
  );
};

export default Key;
