import Word from "./Word";
import GameState from "../types/GameState";
import { useAppSelector } from "../hooks";
import {
  selectCorrectGuesses,
  selectGameStatus,
  selectIncorrectGuesses,
} from "../app/gameSlice";
import { logInfo } from "../helpers/tracing";

type Props = {
  className?: string;
};

const WordGrid = ({ className }: Props) => {
  logInfo({ message: "Rendering WordGrid" });

  const { puzzle } = useAppSelector((state) => state.game);
  const gameState = useAppSelector(selectGameStatus);
  const correctGuesses = useAppSelector(selectCorrectGuesses);
  const incorrectGuesses = useAppSelector(selectIncorrectGuesses);

  const { words, gameIndex: gameNumber } = puzzle;

  const letters = [...correctGuesses, ...incorrectGuesses];

  return (
    <div className={className}>
      <div className="flex flex-col gap-1.5">
        {words.map((word, index) => (
          <Word
            key={word}
            allWords={words}
            wordIndex={index}
            gameNumber={gameNumber}
            answer={word}
            letters={letters}
            reveal={gameState === GameState.LOST}
            showVowels={true}
          />
        ))}
      </div>
    </div>
  );
};

export default WordGrid;
