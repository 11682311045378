import { useEffect, useRef, JSX } from "react";

import ErrorScreen from "./components/ErrorScreen";
import ErrorBoundary from "./components/ErrorBoundary";
import { Outlet } from "react-router";
import { getPlatform, getUid } from "./helpers/system";
import { useAppDispatch, useAppSelector } from "./hooks";
import { selectPageTitle } from "./app/pageSlice";
import { RequestType, request } from "./helpers/requests";
import { setMessages } from "./app/messagesSlice";
import AdUnit from "./components/AdUnit";
import { AdUnitType } from "./types/AdUnitType";
import Header from "./components/Header";
import { useSelector } from "react-redux";
import { ModalType, selectModalType } from "./app/modalSlice";
import InfoModal from "./components/InfoModal";
import StatsModal from "./components/StatsModal";
import { selectGameIndex } from "./app/gameSlice";
import { fetchStats } from "./app/statsSlice";
import { selectIsNative } from "./app/systemSlice";
import "./helpers/nativeBridge";
import { useLocation } from "react-router-dom";
import { locationChanged } from "./app/navigationSlice";
import { UseNativeModal } from "./components/UseNativeModal";

export default function App() {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const gameIndex = useAppSelector(selectGameIndex);

  useEffect(() => {
    dispatch(locationChanged(location.pathname));
  }, [location, dispatch]);

  useEffect(() => {
    // load messages from service
    (async () => {
      const messages = await request(
        "GET",
        "/content/messages.en-US.json",
        undefined,
        true,
        RequestType.Content
      );

      if (messages.status === 200) {
        console.log("Messages", messages.data);
        dispatch(setMessages(messages.data));
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStats(gameIndex));
  }, [dispatch, gameIndex]);

  useEffect(() => {
    const userIdentifier: string = getUid();

    console.log("UID", userIdentifier);

    const platform = getPlatform();
    console.log("Platform", platform);
  }, [dispatch]);

  const pageTitle = useAppSelector(selectPageTitle);

  useEffect(() => {
    if (pageTitle) {
      if (pageTitle.startsWith("#")) {
        document.title = `Hang Five ${pageTitle}`;
      } else {
        document.title = `Hang Five - ${pageTitle}`;
      }
    } else {
      document.title = "Hang Five – Daily Word Puzzle Game";
    }
  }, [pageTitle]);

  const containerRef = useRef<HTMLDivElement>(null);

  const modal = useSelector(selectModalType);

  let modalElement: JSX.Element | null = null;

  switch (modal) {
    case ModalType.Info:
      modalElement = <InfoModal />;
      break;
    case ModalType.Stats:
      modalElement = <StatsModal />;
      break;
    case ModalType.UseNative:
      modalElement = <UseNativeModal />;
      break;
  }

  const isNative = useAppSelector(selectIsNative);

  const showAds = !isNative;
  const showNav = !isNative;

  const notIfNative = (className: string) => (isNative ? "" : className);

  return (
    <>
      {showAds && (
        <AdUnit
          id="left-gutter"
          type={AdUnitType.WideSkyscraper}
          className="fixed hidden xl:block"
          reportPosition="bottom-center"
        />
      )}
      {showAds && (
        <AdUnit
          id="right-gutter"
          type={AdUnitType.WideSkyscraper}
          className="fixed right-0 hidden xl:block"
          reportPosition="bottom-center"
        />
      )}
      <div className={`h-full ${notIfNative("tall:items-center tall:flex")}`}>
        <div
          className={`lg:bg-white lg:dark:bg-slate-900 lg:max-w-md lg:mx-auto lg:shadow-xl lg:h-full ${notIfNative(
            "tall:h-[48em]"
          )} min-w-[21em] md:min-w-[24.5em]`}
        >
          <main
            ref={containerRef}
            className={`pd-2 relative dark:text-white min-h-[100dvh] lg:min-h-[100%] h-full grid ${
              isNative ? "grid-row-[1fr]" : "grid-rows-[2.75em_50px_1fr]"
            }`}
          >
            {showNav && (
              <Header className="p-1.5 bg-violet-600 dark:bg-violet-700 text-white z-10 font-semibold" />
            )}
            {showAds && (
              <AdUnit
                id="top-banner"
                type={AdUnitType.MobileBanner}
                reportPosition="bottom-right-side"
              />
            )}
            <ErrorBoundary fallback={<ErrorScreen />}>
              <div>
                <Outlet />
              </div>
            </ErrorBoundary>
          </main>
        </div>
        {modalElement}
      </div>
    </>
  );
}
