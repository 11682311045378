import Letter from "./Letter";

type Props = {
  answer: string;
  letters: string[];
  reveal: boolean;
  showVowels: boolean;
  allWords: string[];
  wordIndex: number;
  gameNumber: number;
};

const calculateRevealOrderIndex = (
  letter: string,
  allWords: string[],
  wordIndex: number,
  letterPosition: number
) => {
  // Get all letters in the puzzle
  const letters = allWords.join("").split("");

  // how many letters before this word starts?
  const lettersBefore = allWords.slice(0, wordIndex).join("").length;

  // position of this letter in the grand scheme of things
  const globalLetterPosition = lettersBefore + letterPosition;

  return letters.slice(0, globalLetterPosition).filter((l) => l === letter)
    .length;
};

const Word = ({
  answer,
  letters,
  reveal,
  showVowels,
  allWords,
  wordIndex,
}: Props) => {
  let spacerClassName: string = "";

  const maxWordLength = allWords.reduce(
    (max, word) => Math.max(max, word.length),
    0
  );

  switch (maxWordLength) {
    case 6:
      spacerClassName = "basis-7";
      break;
    case 5:
      spacerClassName = "basis-14";
      break;
    case 4:
      spacerClassName = "basis-20";
      break;
  }

  return (
    <div className="flex">
      <div className={spacerClassName}></div>
      {answer.split("").map((letter, index) => (
        <Letter
          key={index}
          isCorrect={letters.includes(letter)}
          letter={letter}
          showVowels={showVowels}
          reveal={reveal}
          revealOrderIndexByLetter={calculateRevealOrderIndex(
            letter,
            allWords,
            wordIndex,
            index
          )}
        />
      ))}
    </div>
  );
};

export default Word;
