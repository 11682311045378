import { useNavigate } from "react-router-dom";
import { selectLearningEndText } from "../app/gameSlice";
import { useAppSelector } from "../hooks";
import Button from "./Button";
import { LEARN_PATH, PLAY_PATH } from "../constants/paths";
import { selectGameIndex } from "../app/learningSlice";

export const LearningGameSummary = () => {
  const endText = useAppSelector(selectLearningEndText);
  const navigate = useNavigate();

  const learningGameIndex = useAppSelector(selectGameIndex);

  const maxGameIndex = 2;

  const nextGameIndex =
    learningGameIndex === undefined || learningGameIndex >= maxGameIndex
      ? undefined
      : learningGameIndex + 1;

  const playDailyPuzzleText = nextGameIndex
    ? "Play today's"
    : "Play today's puzzle";

  return (
    <div className="md:max-w-[24.5em] mx-auto text-xs">
      <p className="mb-2.5">{endText}</p>
      {nextGameIndex && (
        <Button
          className="text-sm mr-1"
          onClick={() =>
            navigate(LEARN_PATH + nextGameIndex, { replace: true })
          }
        >
          Try another
        </Button>
      )}
      <Button
        className="text-sm ml-1"
        variant={nextGameIndex ? "secondary" : "primary"}
        onClick={() => navigate(PLAY_PATH, { replace: true })}
      >
        {playDailyPuzzleText}
      </Button>
    </div>
  );
};
