import { ModalType, showModal } from "../app/modalSlice";
import { goBack } from "../app/navigationSlice";
import { store } from "../app/store";
import { isNative } from "./system";

if (isNative()) {
  window.onmessage = (event: MessageEvent) => {
    switch (event.data) {
      case "backButtonPressed":
        store.dispatch(goBack());
        break;

      case "infoButtonPressed":
        store.dispatch(showModal(ModalType.Info));
        break;
    }
  }
}

type NativeBridgeMessage = "locationChanged" | "finishGame" | "startMigration" | "shareResult";

const androidBridgeMessages: Record<NativeBridgeMessage, (payload?: unknown) => void> = {
  finishGame: () => {
    window?.Android?.finishGame();
  },
  locationChanged: (payload: unknown) => {
    window?.Android?.locationChanged(payload as string);
  },
  shareResult: (payload: unknown) => {
    window?.Android?.shareResult(payload as string);
  },
  startMigration: () => {
    window?.Android?.startMigration();
  }
}

export const postNativeBridgeMessage = (message: NativeBridgeMessage, payload?: unknown) => {
  if (isNative()) {
    // get native platform 
    // assume it's android for now 

    const bridgeMessages = androidBridgeMessages;

    bridgeMessages[message](payload);

    console.log("Posting message to native app", message);

  }
}